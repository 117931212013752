<template>
    <nav class="top-nav">
      <div class="nav-container">
        <button 
          class="menu-toggle" 
          @click="toggleMenu" 
          v-if="isMobileView">
          ☰
        </button>
        <ul 
          :class="{ open: isMenuOpen || !isMobileView }" 
          class="nav-links">
          <li @click="toggleMenu"><router-link to="/">Home</router-link></li>
          <li @click="toggleMenu"><router-link to="/will-of-the-immortals">Will of the Immortals</router-link></li>
          <li @click="toggleMenu"><router-link to="/privateer">Privateer</router-link></li>
          <li @click="toggleMenu"><router-link to="/signed-copies">Signed Copies</router-link></li>
          <li @click="toggleMenu"><router-link to="/about">About Me</router-link></li>
        </ul>
      </div>
    </nav>
  </template>
  
  <script>
  export default {
    name: "TopNav",
    data() {
      return {
        isMenuOpen: false,
        isMobileView: false,
      };
    },
    methods: {
      toggleMenu() {
        this.isMenuOpen = !this.isMenuOpen;
      },
      checkViewport() {
        this.isMobileView = window.innerWidth <= 768;
      },
    },
    mounted() {
      this.checkViewport();
      window.addEventListener("resize", this.checkViewport);
    },
    beforeUnmount() {
      window.removeEventListener("resize", this.checkViewport);
    },
  };
  </script>
  
  <style scoped>
  /* General Navigation Styling */
  * {
    box-sizing: border-box;
    margin: 0;
    padding: 0;
  }
  
  body {
    overflow-x: hidden; /* Prevents horizontal scrolling */
  }
  
  .top-nav {
    background-color: #041c2c;
    color: white;
    padding: 1rem;
    position: sticky;
    top: 0;
    width: 100%;
    z-index: 1000;
    margin: 0; /* Ensure no margin above the nav */
  }
  
  /* Container to Align Content */
  .nav-container {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    max-width: 1200px;
    margin: 0 auto;
    width: 100%; /* Ensures full width */
  }
  
  /* Navigation Links */
  .nav-links {
    list-style: none;
    display: flex;
    gap: 1.5rem;
  }
  
  .nav-links a {
    color: white;
    text-decoration: none;
    font-size: 1rem;
  }
  
  .nav-links a:hover {
    text-decoration: underline;
  }
  
  /* Hamburger Button - Hidden on Desktop */
  .menu-toggle {
    background: none;
    border: none;
    color: white;
    font-size: 1.5rem;
    cursor: pointer;
    display: none;
  }
  
  /* Mobile View */
  @media (max-width: 768px) {
    .menu-toggle {
      display: block;
      margin-left: auto;
      margin-right: auto;
    }
  
    /* Hide links initially on mobile */
    .nav-links {
      display: none;
      flex-direction: column;
      width: 100%; /* Full width on mobile */
      background-color: #444;
      position: absolute;
      top: 100%;
      left: 0;
      padding: 1rem;
      border-radius: 0 0 10px 10px;
    }
  
    .nav-links.open {
      display: flex;
    }
  }
  </style>
  