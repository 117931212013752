<template>
    <div class="signed-copies-page">
      <h1>Signed Copies</h1>
      <h4>Get your signed copies of my books here!</h4>
        <p>I have signed copies of my novels available here. I do my best to keep the in-stock and out of stock statuses up to date, but if you have any questions, please feel free to reach out to me at jay@jaykrauss.com, my Patreon or Facebook at Jay Krauss - Author.</p>
        <p>These items will ship within a few days of the order being received, and shipped USPS (shipping is included in the price).</p>
        <p>Payment is all done through Shopify to keep things simple.</p>
        <p>Should you have any other needs, please reach out.</p>
        <p>Thank you for your support!</p>

        <p><b>Please note that Stormbound Path is still in preorder. Any signed copies ordered will be shipped after the book releases on July 2nd.</b></p>

        <p>Currently shipping: Steel Foundations (Both), Runic Ocean (Both), Ironheart Crucible (Both), Spirit's Call (Both, limited stock).</p>
      <div class="product-grid">
        <ShopifyButton
          v-for="product in products"
          :key="product.componentId"
          :productId="product.id"
          :componentId="product.componentId"
          :domain="shopDomain"
          :storefrontAccessToken="storefrontAccessToken"
        />
      </div>
    </div>
  </template>
  
  <script>
  import ShopifyButton from "@/components/ShopifyButton.vue";
  
  export default {
    name: "SignedCopies",
    components: { ShopifyButton },
    data() {
      return {
        shopDomain: "j51rbs-bh.myshopify.com",
        storefrontAccessToken: "898dca331ef05cef8ee208dab36a18e1",
        products: [
          { id: "9790195302683", componentId: "product-component-1736201262417" },
          { id: "9790195925275", componentId: "product-component-1736201696988" },
          { id: "9790195532059", componentId: "product-component-1736201712325" },
          { id: "9790196056347", componentId: "product-component-1736202415913" },
          { id: "9790195761435", componentId: "product-component-1736201738882" },
          { id: "9790196154651", componentId: "product-component-1736201750978" },
          { id: "9830479331611", componentId: "product-component-1741637008933" },
          { id: "9830479167771", componentId: "product-component-1741636948024" },
          { id: "9845454569755", componentId: "product-component-1743375095145" },
          { id: "9845454471451", componentId: "product-component-1743375011268" },
          { id: "9790197006619", componentId: "product-component-1736201761800" },
        ],
      };
    },
  };
  </script>
  
  <style scoped>
        .signed-copies-page {
        text-align: center;
        margin: 20px;
        }

        .product-grid {
        display: grid;
        gap: 20px;
        grid-template-columns: repeat(auto-fit, minmax(300px, 1fr)); /* Responsive centering */
        justify-content: center; /* Center items in the grid */
        align-items: center;
        max-width: 1200px; /* Limit the grid width */
        margin: 0 auto; /* Center the grid itself */
        padding: 20px;

        /* Add padding to offset the shopping cart icon */
        padding-right: 50px; /* Adjust this value if needed */
        }

        @media (min-width: 768px) {
        .product-grid {
            grid-template-columns: repeat(2, 1fr); /* Two columns for desktop */
            padding-left: 10%;
        }
        }
  </style>
  