<template>
  <div class="home">

    <!-- Profile Section -->
    <div class="diagonal-section light-section">
      <section class="profile-section">
        <div class="profile-container">
          <img class="profile-image" src="https://jaykraussauthor.s3.amazonaws.com/jayauthorpicture.jpg"
            alt="Profile Image" />
          <div class="profile-text">
            <p>Jay is an animal lover, software developer, traditional blacksmith, modern woodworker, terrible mechanic,
              avid reader, and author.</p>
            <p>A master of no trades, perhaps, but a consummate dabbler.</p>
            <p>Writing in both Sci-Fi and Fantasy genres.</p>
          </div>
        </div>
      </section>
    </div>

    <!-- Series Section -->
    <div class="diagonal-section dark-section">
      <section class="series-section">
        <div class="series-container">
          <div class="series-box">
            <router-link to="/will-of-the-immortals" class="series-link">
              <img src="https://jaykraussauthor.s3.us-east-1.amazonaws.com/NEW+Steel+Foundations+Backgroun.png"
                alt="Will of the Immortals" class="series-image" />
            </router-link>
            <h2>Will of the Immortals</h2>
            <p>An epic journey of cultivation and willpower.</p>
          </div>

          <div class="series-box">
            <router-link to="/privateer" class="series-link">
              <img src="https://jaykraussauthor.s3.amazonaws.com/privateerseriesimage.png" alt="Privateer"
                class="series-image" />
            </router-link>
            <h2>Privateer</h2>
            <p>Chart a path through the stars.</p>
          </div>
        </div>
      </section>
    </div>

    <!-- Socials Section -->
    <div class="diagonal-section light-section">
      <section class="socials-section">
        <h2>Connect with me</h2>
        <div class="socials-links">
          <a class="btn social-button facebook" href="https://www.facebook.com/profile.php?id=61567395804159"
            target="_blank">
            <i class="fab fa-facebook-f"></i> Facebook
          </a>
          <a class="btn social-button amazon" href="https://www.amazon.com/stores/Jay-Krauss/author/B0DHLFG6BB"
            target="_blank">
            <i class="fab fa-amazon"></i> Amazon
          </a>
          <a class="btn social-button discord" href="https://discord.gg/kY8a8jXMG9" target="_blank">
            <i class="fab fa-discord"></i> Discord
          </a>
        </div>
      </section>
    </div>

    <!-- Patreon Section -->
    <div class="patreon-highlight-section dark-section flat-section">
      <center>
        <section class="patreon-section">
          <h2 class="patreon-title">Support My Work on Patreon</h2>
          <p class="patreon-blurb">
            Get early access to exclusive content—including a brand-new chapter every week from the current book in
            progress. I update regularly and love sharing the journey with my supporters.
          </p>
          <a class="btn patreon-button" href="https://www.patreon.com/jaykrauss" target="_blank">
            <i class="fab fa-patreon"></i> Join Me on Patreon
          </a>
        </section>
      </center>
    </div>
  </div>
</template>

<script>
export default {
  name: 'HomePage',
};
</script>

<style scoped>
/* Hero Section */
.hero-section {
  position: relative;
  width: 100%;
  height: 60vh;
  max-height: 300px;
  background-color: #1f1f1f;
  clip-path: polygon(0 0, 100% 0, 100% 90%, 0 100%);
}

.hero-image {
  width: 100%;
  height: 100%;
  object-fit: cover;
  opacity: 0.5;
}

.overlay-text {
  position: absolute;
  color: white;
  text-align: center;
}

.dark-section {
  min-height: 600px;
}

/* Profile Section */
.profile-section {
  width: 100%;
  display: flex;
  justify-content: center;
  min-height: 350px;
}

.profile-container {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  max-width: 800px;
  text-align: center;
}

.profile-image {
  width: 150px;
  height: 150px;
  border-radius: 50%;
  object-fit: cover;
  margin-right: 1rem;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 20px;
}

/* Series Section */
.series-container {
  display: flex;
  justify-content: space-around;
  flex-wrap: wrap;
  gap: 2rem;
}

.series-box {
  max-width: 300px;
  text-align: center;
  margin: 10px;
  color: white;
  transition: transform 0.3s ease, box-shadow 0.3s ease;
  border-radius: 10px;

  h2 {
    color: white;
    margin-top: 10px;
  }
}

.series-box:hover {
  transform: translateY(-5px);
  box-shadow: 0 8px 20px rgba(0, 0, 0, 0.3);
}

.series-image {
  width: 225px;
  height: 350px;
  object-fit: cover;
}

/* Socials Section */
.socials-section {
  text-align: center;
}

.socials-links {
  display: flex;
  justify-content: center;
  gap: 1rem;
}

/* Responsive Design */
@media (max-width: 768px) {
  .series-container {
    flex-direction: column;
    align-items: center;
    padding-top: 100px;
    padding-bottom: 100px;
  }

  .profile-container {
    flex-direction: column;
  }

  .socials-links {
    flex-direction: column;
    align-items: center;
  }

  .social-button {
    width: 80%; /* or 100% if you want full width */
    justify-content: center;
  }
}

.social-button {
  font-weight: bold;
  padding: 0.75rem 1.5rem;
  border-radius: 8px;
  color: white;
  text-decoration: none;
  font-size: 1.1rem;
  display: flex;
  align-items: center;
  gap: 0.5rem;
  transition: transform 0.2s ease, box-shadow 0.3s ease;
}

.social-button:hover {
  transform: scale(1.05);
  box-shadow: 0 0 12px rgba(0, 0, 0, 0.2);
}

.facebook {
  background-color: #1877F2;
}

.amazon {
  background: linear-gradient(45deg, #232f3e, #000000);
}

.discord {
  background-color: #5865F2;
}

.patreon-highlight-section {
  background: linear-gradient(135deg, #041c2c, #083e63);
  padding: 5rem 2rem;
  color: white;
  clip-path: none;
}

.patreon-title {
  font-size: 2rem;
  font-weight: bold;
  margin-bottom: 1.5rem;
  text-shadow: 1px 1px 4px rgba(0, 0, 0, 0.3);
  position: relative;
}

.patreon-title::after {
  content: '';
  display: block;
  width: 60px;
  height: 3px;
  margin: 0.5rem auto 0;
  background: white;
  border-radius: 2px;
  animation: pulseUnderline 2s infinite;
}

@keyframes pulseUnderline {

  0%,
  100% {
    opacity: 0.5;
    transform: scaleX(1);
  }

  50% {
    opacity: 1;
    transform: scaleX(1.2);
  }
}

.patreon-blurb {
  font-size: 1.15rem;
  line-height: 1.6;
  margin-bottom: 2rem;
  color: #e0f3ff;
}

.patreon-button {
  background-color: white;
  color: #0f5c8d;
  font-weight: bold;
  font-size: 1rem;
  padding: 1rem 2rem;
  border-radius: 12px;
  text-decoration: none;
  box-shadow: 0 0 15px rgba(255, 255, 255, 0.3);
  transition: all 0.3s ease-in-out;
  display: inline-flex;
  align-items: center;
  gap: 0.5rem;
}

.patreon-button:hover {
  background-color: #d8ecf7;
  transform: scale(1.05);
  box-shadow: 0 0 25px rgba(255, 255, 255, 0.4);
}
</style>
